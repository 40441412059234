// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const enabledGestures = {FSrC4zj5f: {hover: true}};

const cycleOrder = ["FSrC4zj5f"];

const serializationHash = "framer-7ZKQr"

const variantClassNames = {FSrC4zj5f: "framer-v-11paytn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({background, height, iconName, id, link, tap, width, ...props}) => { return {...props, gYvH0Jb8m: background ?? props.gYvH0Jb8m ?? "var(--token-e065af15-a162-4041-821a-20e240d9ad3d, rgb(0, 0, 0))", h2PDxjAk5: iconName ?? props.h2PDxjAk5 ?? "MoreHoriz", mb1wTwqUY: tap ?? props.mb1wTwqUY, uxMdoVgTZ: link ?? props.uxMdoVgTZ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconName?: string;link?: string;tap?: any;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, h2PDxjAk5, uxMdoVgTZ, mb1wTwqUY, gYvH0Jb8m, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FSrC4zj5f", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1cz4l7t = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (mb1wTwqUY) {
const res = await mb1wTwqUY(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={uxMdoVgTZ} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-11paytn", className, classNames)} framer-v8s8m0`} data-framer-name={"Button"} data-highlight data-reset={"button"} layoutDependency={layoutDependency} layoutId={"FSrC4zj5f"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1cz4l7t} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: gYvH0Jb8m, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} variants={{"FSrC4zj5f-hover": {backgroundColor: "rgba(0, 0, 0, 0.8)"}}} {...addPropertyOverrides({"FSrC4zj5f-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1g2uijj-container"} layoutDependency={layoutDependency} layoutId={"yiQ6z9EoM-container"}><Material color={"var(--token-0109aa55-a8db-4917-9953-a1fb1bfad426, rgb(255, 255, 255))"} height={"100%"} iconSearch={"Home"} iconSelection={h2PDxjAk5} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"yiQ6z9EoM"} layoutId={"yiQ6z9EoM"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7ZKQr.framer-v8s8m0, .framer-7ZKQr .framer-v8s8m0 { display: block; }", ".framer-7ZKQr.framer-11paytn { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; padding: 13px 13px 13px 13px; position: relative; text-decoration: none; width: min-content; }", ".framer-7ZKQr .framer-1g2uijj-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7ZKQr.framer-11paytn { gap: 0px; } .framer-7ZKQr.framer-11paytn > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-7ZKQr.framer-11paytn > :first-child { margin-left: 0px; } .framer-7ZKQr.framer-11paytn > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ZtpmImvGA":{"layout":["auto","auto"]}}}
 * @framerVariables {"h2PDxjAk5":"iconName","uxMdoVgTZ":"link","mb1wTwqUY":"tap","gYvH0Jb8m":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNjG6QB6jM: React.ComponentType<Props> = withCSS(Component, css, "framer-7ZKQr") as typeof Component;
export default FramerNjG6QB6jM;

FramerNjG6QB6jM.displayName = "Button / Icon - Active";

FramerNjG6QB6jM.defaultProps = {height: 46, width: 46};

addPropertyControls(FramerNjG6QB6jM, {h2PDxjAk5: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "MoreHoriz", description: undefined, hidden: undefined, title: "Icon Name"}, uxMdoVgTZ: {title: "Link", type: ControlType.Link}, mb1wTwqUY: {title: "Tap", type: ControlType.EventHandler}, gYvH0Jb8m: {defaultValue: "var(--token-e065af15-a162-4041-821a-20e240d9ad3d, rgb(0, 0, 0))", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerNjG6QB6jM, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})